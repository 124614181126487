import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import Headroom from 'headroom.js';
import $ from 'jquery';
import 'magnific-popup';
import 'slick-carousel';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    //Fixes jittering on signup page caused by scrolltop function found in the original memberpress plugin files.
    $(window).load( () => {
      if($('body.single-memberpressproduct').length > 0) {
        $('body.single-memberpressproduct').off('change');
      }
    });
    if(!this.$body.hasClass('page-template-landing')) {
      this.initHeadroom();
    }
    this.initPopups();
    this.initSliders();
    this.initCustomSelect();
    this.initBanner();
    if (this.$body.hasClass('page-template-map')) {
      this.mapCitySearch();
    }
    if(this.$body.hasClass('page-template-explore')) {
      this.initCountryFilterAjax();
    }
    if(this.$body.hasClass('tax-city-category')) {
      this.initTypeShopFilterAjax();
      this.loadOnScroll(1, 5);
    }
    if(this.$body.hasClass('page-template-journal') ) {
      this.initJournalFilterAjax();
      if($(window).width() > 1024) {
        this.loadOnScroll(2, 4);
      }
    }
    if(this.$body.hasClass('memberpressproduct-template-default')) {
      this.addCouponDescription();
    }
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
    this.$banner = $('.js-banner');
    this.$mainWrap = $('main').css('paddingTop');
    this.$allPosts = {};
    this.$tempPosts = {};
    this.$currentPostPage = {}; // 1
    this.$maxPostPage = {};
    this.$postsPerPage = {};
  },
  events() {
    this.$doc.on(
      'click',
      '.js-hamburger',
      event => this.showMobileNav(event)
    );
    this.$doc.on(
      'click',
      '.js-share',
      event => this.openShareWindow(event)
    );
    this.$win.on(
      'load',
      event => this.mapInit(event)
    );
    this.$doc.on(
      'click',
      '.banner__close',
      event => this.closeBanner(event)
    );
  },

  mapCitySearch(){
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const city = urlParams.get('city-search')

    if(city){
      console.log('CITY ---------- ' + city);
      var selectvalue = jQuery('select[name="place_city"] option[value="' + city + '"]').prop("selected", true);
      jQuery(selectvalue).trigger('click');
    }
    else{
      console.log('No CITY parameter');
    }
  },

  closeBanner(event) {
    event.preventDefault()
    this.$banner.removeClass('is-loaded');
    $('.js-header').css('paddingTop', 0);
    $('main').css('paddingTop', `${this.$mainWrap}`);
    this.$banner.css('top', `-${this.$banner.outerHeight()}px`);
  },

  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { 'offset' : 100, 'tolerance' : 5 });
    headroom.init();
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if ( this.$mobileNav.hasClass('is-active') ) {
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
    }
  },
  initBanner() {
    if (this.$banner.length > 0) {
      this.$banner.addClass('is-loaded');
      const mainPadding = parseInt(this.$mainWrap) + this.$banner.outerHeight();
      $('.js-header').css('paddingTop', `${this.$banner.outerHeight()}px`);
      $('main').css('paddingTop', `${mainPadding}px`);
      this.$banner.css('top', 0);
    }
  },
  initPopups() {
    $('.js-gallery-popup').magnificPopup({
    type: 'image',
    gallery:{
      enabled:true
    },
    midClick: true,
    removalDelay: 100,
    mainClass: 'animated fadeIn'
    });
    $('.js-contactform-modal > a').magnificPopup({
      type:'inline',
      mainClass: 'popup--animate',
      fixedContentPos: true,
      closeBtnInside: true,
    });
    $('.js-loginform-modal > a:contains("Log In")').magnificPopup({
      type:'inline',
      mainClass: 'popup--animate',
      fixedContentPos: true,
      closeBtnInside: true,
    });

    $('.js-registrationform-modal').magnificPopup({
      type:'inline',
      mainClass: 'popup--animate',
      fixedContentPos: true,
      closeBtnInside: true,
    });
  },
  initSliders() {
    $('.js-testimonial-slider').slick({
      dots: false,
      arrows: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 15000,
      infinite: true
    });
    $('.js-landing-slider').slick({
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        variableWidth: true,
        draggable: false,
        pauseOnFocus: true,
        pauseOnHover: true
    }).on("beforeChange", function(e, slick) {
        var width = $('.js-landing-slider .slick-current').outerWidth();
        var speed = (width*3000)/200;
        $('.js-landing-slider').slick("setOption", "speed", speed);
    });
  },
  mapInit(event){
    var map = $(".wpgmp_map_parent");
    var filters = $(".wpgmp_filter_wrappers");
    var results;
    if( $('div[class*="location_listing"]').length > 0 ){
      results = $('div[class*="location_listing"]');
    }
    $(".wpgmp_filter_wrappers").append("<a href='javascript:void(0)' id='showResults' class='map__mobile-link'>Show results</a>");
    $(".wpgmp_filter_wrappers").prepend("<a href='javascript:void(0)' id='hideSearch' class='map__mobile-search--hide'>Hide filters</a>");
    $(".map").prepend("<a href='javascript:void(0)' id='showSearch' class='map__mobile-search--show'>Show filters</a>");
    $(results).addClass("map__results");

    $(".fc-item-box").last().css('margin-bottom','0 !important');
    $(".map").addClass("visible");
    $("#showResults").on('click', function(){
      $(".map__results").slideToggle().toggleClass('results-visible');
      if($(".results-visible").length) $("#showResults").text("Hide results");
      else $("#showResults").text("Show results");
    });

    $(".place_title").bind('click', function(){
      if($(".results-visible").length) $(".map__results").slideToggle().toggleClass('results-visible');
      else return;
    });

    $("#hideSearch").on('click', function(){
      $(".wpgmp_filter_wrappers").toggle();
      $(".map").toggleClass("hidden-filters");
      $(".map__mobile-search--show").toggle();
    });

    $("#showSearch").on('click', function(){
      $(".wpgmp_filter_wrappers").toggle();
      $(".map").toggleClass("hidden-filters");
      $(".map__mobile-search--show").toggle();
      if($(".results-visible").length){
        $(".map__results").removeClass('results-visible');
        $("#showResults").text("Show results");
      }
      $("#showResults").text("Show results");
    });
  },
  initTypeShopFilterAjax() {
    var self = this;
    var typeShopAjax = function () {
      var city = jQuery('#type-shop-filter').data('city');
      // console.log(city);
      var category = jQuery('.select-selected').attr('value');
      // console.log(category);
      if(category === 0) {
        category = "View all";
      }
      // console.log(category);
      var adminAjaxUrl = jQuery('#adminAjaxUrl').val();

      jQuery.ajax({
        url:adminAjaxUrl,
        type:'POST',
        data:{
          city:city,
          category: category,
          action:'shop_ajax'
        },
        beforeSend:function(xhr){
          $('#ajax-loading').show();
          $('#type-shop-list').fadeOut();
        },
        success:function(response){
          $('#ajax-loading').hide();
          self.$allPosts = $(response.data);
          if($(window).width() > 1024) {
            self.$maxPostPage = Math.ceil(self.$allPosts.length/ 5); //Count Max posts pages
            self.$currentPostPage = 1;
            self.$postsPerPage = 8; // is 8 on initial load or filter change
            self.$tempPosts = self.$allPosts; //Move all posts to $tempPosts[id] var for further use

            var posts_to_append = paginate(self.$tempPosts, self.$postsPerPage, self.$currentPostPage);
          }
          $('#type-shop-list').html(posts_to_append).fadeIn();

          function paginate(array, page_size, page_number) {
              --page_number;
              return array.slice(page_number * page_size, (page_number + 1) * page_size);
          }
        }
      });
    }

    $('body').on('DOMSubtreeModified', '#type-category-filter > .select-selected', function() {
      var waitUpdate = setInterval(function(){
        if($("#type-category-filter > .select-selected").attr('value') != null){
          var selectedValue = $("#type-category-filter > .select-selected").attr('value');
          if(selectedValue) {
            typeShopAjax(parseInt(selectedValue));
          }
          clearInterval(waitUpdate);
        }
      },1);
    });

    $(document).ready(function(){
      typeShopAjax();
    })
  },
  initJournalFilterAjax() {
    var self = this;
    var journalAjax = function () {
      var category = jQuery('.select-selected').attr('value');
      if(category === 0) {
        category = "View all";
      }
      var adminAjaxUrl = jQuery('#adminAjaxUrl').val();

      jQuery.ajax({
        url: adminAjaxUrl,
        type: 'POST',
        data:{
          category: category,
          action:'journal_ajax'
        },
        beforeSend:function(xhr){
          $('#ajax-loading').show();
          $('#journal__list').fadeOut();
        },
        success:function(response){
          $('#ajax-loading').hide();

          self.$allPosts = $(response.data);
          var posts_to_append = self.$allPosts;
          if($(window).width() > 1024) {
            self.$maxPostPage = Math.ceil((self.$allPosts.length - 4)/ 4); //Count Max posts pages
            self.$currentPostPage = 1;
            self.$postsPerPage = 8; // is 8 on initial load or filter change
            self.$tempPosts = self.$allPosts; //Move all posts to $tempPosts[id] var for further use

            var posts_to_append = paginate(self.$tempPosts, self.$postsPerPage, self.$currentPostPage);
          }
          $('#journal__list').html(posts_to_append).fadeIn();

          function paginate(array, page_size, page_number) {
              --page_number;
              return array.slice(page_number * page_size, (page_number + 1) * page_size);
          }
        }
      });
    }
    $('body').on('DOMSubtreeModified', '#journal-category-filter > .select-selected', function() {
      var waitUpdate = setInterval(function(){
        if($("#journal-category-filter > .select-selected").attr('value') != null){
          self.$allPosts = {};
          self.$tempPosts = {};
          journalAjax();
          clearInterval(waitUpdate);
        }
      },1);
    });
    $(document).ready(function(){
      journalAjax();
    })
  },
  initCountryFilterAjax(selectedCountry) {
    var countryAjax = function (selectedValue) {
      var category = selectedValue;
      var adminAjaxUrl = jQuery('#adminAjaxUrl').val();
      jQuery.ajax({
        url:adminAjaxUrl,
        type:'POST',
        data:{
          category: category,
          action: 'country_ajax'
        },
        beforeSend:function(xhr){
          $('#ajax-loading').show();
          $('#city-category-list').fadeOut();
        },
        success:function(response){
          $('#ajax-loading').hide();
          $('#city-category-list').html(response);
          $('#city-category-list').fadeIn();
        }
      });
    }

    $('body').on('DOMSubtreeModified', '#country-category-filter > .select-selected', function() {
      var waitUpdate = setInterval(function(){
        var selectedValue = $("#country-category-filter > .select-selected").attr('value');
        if(selectedValue) {
          countryAjax(parseInt(selectedValue));
        }
        clearInterval(waitUpdate);
      },1);
    });
  },
  loadOnScroll(cpp,ppp) {
    var self = this;
    this.$currentPostPage = cpp;
    this.$body.on('touchmove', onScroll);
    $(window).on('scroll', onScroll);
    function onScroll(){
        if($(window).scrollTop() == $(document).height() - $(window).height()) {
          self.$postsPerPage = ppp;
          self.$currentPostPage++;

          if( self.$tempPosts !== 0 ) {
            var posts_to_append = paginate(self.$tempPosts, self.$postsPerPage, self.$currentPostPage);
          }
          if(self.$body.hasClass('tax-city-category')) {
            $('#type-shop-list').append(posts_to_append).fadeIn();
          }
          if(self.$body.hasClass('page-template-journal')) {
            $('#journal__list').append(posts_to_append).fadeIn();
          }

          //Check if we've reached max posts page + 1
          if (self.$currentPostPage >= (self.$maxPostPage + 1)) {
            return;
          }

          // Helpers in Cosole below
          console.log('After click Max Pages: ' + self.$maxPostPage);
          console.log('After click Current Page: ' + self.$currentPostPage);
          console.log('After click Posts Per Page: ' + self.$postsPerPage);
          console.log("posts_to_append", posts_to_append);

          function paginate(array, page_size, page_number) {
              --page_number;
              return array.slice(page_number * page_size, (page_number + 1) * page_size);
          }
          $(window).scrollTop($(window).scrollTop()-1);
        }
    }
  },
  initCustomSelect() {
    var x, i, j, selElmnt, a, b, c;
    var self = this;
    /* Look for any elements with the class "custom-select": */
    x = document.getElementsByClassName("custom-select");
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      /* For each element, create a new DIV that will act as the selected item: */
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.setAttribute("value", selElmnt.options[selElmnt.selectedIndex].getAttribute("value"));
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      /* For each element, create a new DIV that will contain the option list: */
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 0; j < selElmnt.length; j++) {
        /* For each option in the original select element,
        create a new DIV that will act as an option item: */
        c = document.createElement("DIV");
        if(j == 0) {
          c.setAttribute("class", "same-as-selected");
        }
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.setAttribute("value", selElmnt.options[j].getAttribute("value"));
        c.addEventListener("click", function(e) {
            /* When an item is clicked, update the original select box,
            and the selected item: */
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
              if (s.options[i].innerHTML == this.innerHTML) {
                s.selectedIndex = i;
                h.innerHTML = this.innerHTML;
                h.setAttribute("value", this.getAttribute("value"));
                y = this.parentNode.getElementsByClassName("same-as-selected");
                for (k = 0; k < y.length; k++) {
                  y[k].removeAttribute("class");
                }
                this.setAttribute("class", "same-as-selected");
                break;
              }
            }
            h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function(e) {
        /* When the select box is clicked, close any other select boxes,
        and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }

    function closeAllSelect(elmnt) {
      /* A function that will close all select boxes in the document,
      except the current select box: */
      var x, y, i, arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i)
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }

    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */
    document.addEventListener("click", closeAllSelect);
  },
  addCouponDescription() {
    $('.mepr_coupon').append('<div class="coupon-text">Please note that you will be billed in a month if you don&apos;t cancel your subscription previously.</div>');
  }

};
Mangrove.init();
